﻿<script setup lang="ts">
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import gsap from 'gsap';

const scrollSmoother = ref();

function appendScriptToBody() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    if (!document.querySelector('script[src="/js/ScrollSmoother.min.js"]')) {
        const script = document.createElement('script');
        script.src = '/js/ScrollSmoother.min.js';
        script.async = false;
        script.addEventListener('load', initScrollSmoother);
        document.body.appendChild(script);
        return;
    }
    initScrollSmoother();
}

function initScrollSmoother() {
    if(typeof ScrollSmoother === 'function'){
        gsap.registerPlugin(ScrollSmoother);
        scrollSmoother.value = ScrollSmoother.create({
            wrapper: '.wrapper',
            content: '.content',
            smooth: 1,
            effects: true,
        });
    }
}

function removeScrollSmoother() {
    if (scrollSmoother.value && typeof scrollSmoother.value.kill === 'function') {
        scrollSmoother.value.kill();
    }
    document.body.removeAttribute('style');
}

onMounted(() => setTimeout(appendScriptToBody, 500));
// onMounted(appendScriptToBody);
onBeforeUnmount(removeScrollSmoother);

</script>

<template>
    <slot />
</template>
