import { createStore } from 'vuex';

const store = createStore({
    state () {
        return {
            options: undefined,
            optionsLoad: false,
            page: {},
            pageLoading: true,
            pages: {},
            iso_code: sessionStorage.iso_code ?? 'RU-MOW',
            //location: sessionStorage.location,
            //ip_location: sessionStorage.ip_location,
            locations: undefined,
            shops: undefined,
            modal: {
                location: undefined
            },
        }
    },
    mutations: {
        setOptions (state: any, options: object) {
            state.options = options;
            state.optionsLoad = true;
        },
        setPage (state: any, page: object) {
            state.page = page;
            state.pageLoading = false;
        },
    },
    getters: {
        /*
        iso_code (state: any, getters: object) {
            console.log( state );
            if(state.iso_code){
                return state.iso_code;
            }
            if(state.ip_location){
                const ip_location = JSON.parse(state.ip_location);
                console.log( ip_location );
                if(ip_location?.locationName){
                    return ip_location.locationName;
                }
            }
            return 'RU-MOW';
        },
        */
        regionName (state: any, getters: object) {
            if(state.options?.regions?.length){
                // @ts-ignore
                const region = state.options.regions.find((e) => e.iso_code === state.iso_code);
                if(region?.name){
                    return region.name;
                }
                return 'Москва';
            }
        },
        /*
        location (state: any, getters: object) {
            if(state.location){
                return state.location;
            }
            if(state.ip_location){
                const ip_location = JSON.parse(state.ip_location);
                if(ip_location?.locationName){
                    return ip_location.locationName;
                }
            }
            return 'Москва';
        }
        */
    }
})

export default store;
