export function scrollEl( id ){
    if(id){
        const el = document.querySelector('#' + id);
        if (el && el.offsetTop) {
            window.scrollTo({
                top: el.offsetTop,
                behavior: 'smooth'
            });
        }
    }
}