import './assets/scss/main.scss';

import { createApp } from 'vue';
import store from "./store";
import router from './router';
import App from './App.vue';

const app = createApp(App);

app.config.performance = true;

app.use(store);
app.use(router);

app.directive('click-outside', {
    beforeMount: function (element, binding) {
        console.log({
            element,
            binding
        });
        // @ts-ignore
        element.clickOutsideEvent = function (event) {
            // and if it did, call method provided in attribute value
            if (!(element === event.target || element.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unmounted: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
    }
});

app.mount('#app');
