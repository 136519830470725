import {createRouter, createWebHistory, useRoute} from 'vue-router';
import store from "@/store";

import HomeView from '../views/HomeView.vue';
import {computed, watch} from "vue";

window.statePreventScrollTop = false;
// window.stateOptions = {};
// window.statePage = {};
// window.statePages = {};


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/catalog',
            name: 'catalog',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/catalog/:filters*',
            name: 'catalog-filters',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/product/:slug',
            name: 'product',
            component: () => import('@/views/ProductView.vue'),
        },
        {
            path: '/where-to-buy',
            name: 'where-to-buy',
            component: () => import('@/views/WhereToBuyView.vue'),
        },
        {
            path: '/where-to-buy/:article',
            name: 'where-to-buy-article',
            component: () => import('@/views/WhereToBuyView.vue'),
        },
        {
            path: '/product-line',
            name: 'product-line',
            component: () => import('@/views/ProductLineView.vue'),
        },
        {
            path: '/brit-premium',
            name: 'brit-premium',
            component: () => import('@/views/BritPremiumView.vue'),
        },
        {
            path: '/brit-care',
            name: 'brit-care',
            component: () => import('@/views/BritCareView.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/AboutView.vue'),
        },

        {
            path: '/posts',
            name: 'posts',
            component: () => import('@/views/PostsView.vue'),
        },
        {
            path: '/posts/:tag',
            name: 'posts_tag',
            component: () => import('@/views/PostsView.vue'),
        },
        {
            path: '/post/:slug',
            name: 'post',
            component: () => import('@/views/PostView.vue'),
        },

        /*
        {
            path: '/interesting',
            name: 'interesting',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles',
            name: 'articles',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles/:slug',
            name: 'article',
            component: () => import('@/views/SingleArticleView.vue'),
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/news/:slug',
            name: 'news-single',
            component: () => import('@/views/SingleNewsView.vue'),
        },
        {
            path: '/events',
            name: 'events',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/events/:slug',
            name: 'event',
            component: () => import('@/views/SingleEventView.vue'),
        },
        {
            path: '/contests',
            name: 'contests',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/contests/:id',
            name: 'contest',
            component: () => import('@/views/SingleContestView.vue'),
        },
        */

        {
            path: '/for-pet-owners',
            name: 'for-pet-owners',
            component: () => import('@/views/ForPetOwnersView.vue'),
        },
        {
            path: '/for-breeders-&-kennels',
            name: 'for-breeders-&-kennels',
            component: () => import('@/views/ForBreeders&KennelsView.vue'),
        },
        {
            path: '/for-veterinary-clinics',
            name: 'for-veterinary-clinics',
            component: () => import('@/views/ForVetClinicsView.vue'),
        },
        {
            path: '/obrititedruga',
            name: 'obrititedruga',
            component: () => import('@/views/OBrititeDrugaView.vue'),
        },
        {
            path: '/britfriends',
            name: 'britfriends',
            component: () => import('@/views/BritFriendsView.vue'),
        },
        {
            path: '/ambassadog',
            name: 'ambassadog',
            component: () => import('@/views/AmbassaDogView.vue'),
        },
        {
            path: '/partners',
            name: 'partners',
            component: () => import('@/views/PartnersView.vue'),
        },
        {
            path: '/faq',
            name: 'faq',
            component: () => import('@/views/FAQView.vue'),
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('@/views/Search.vue'),
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () => import('@/views/Page.vue'),
        },
        {
            path: '/:catchAll(.*)',
            component: () => import('@/views/CatchAll.vue'),
        },
        {
            path: '/404',
            component: () => import('@/views/NotFound.vue'),
        },

        /*
        {
            path: '/dynamo',
            name: 'dynamo',
            component: () => import('@/views/DynamoView.vue'),
        },
        */

        /*
        {
            path: '/ambassadors',
            name: 'ambassadors',
            component: () => import('@/views/AmbassadorsView.vue'),
        },
        {
            path: '/ambassador/:id',
            name: 'ambassador',
            component: () => import('@/views/AmbassadorView.vue'),
        },
        {
            path: '/for-partners',
            name: 'for-partners',
            component: () => import('@/views/ForPartnersView.vue'),
        },
        */

    ],
    async scrollBehavior(to, from, savedPosition) {
        getStateOptions();

        let delay = 100;

        if (from.name === 'home') {
            resetFooterDogPawsPosition();
            delay = 500;
        }

        if(to.name === 'catalog' || to.name === 'catalog-filters'){
            store.state.pageLoading = false;
        }
        else{
            if (!savedPosition) {
                store.state.pageLoading = true;
            }
            setStatePage(to.path);
        }

        if (savedPosition) {
            await new Promise(resolve => setTimeout(resolve, delay));
            return savedPosition;
        }
        else if (window.statePreventScrollTop) {
            window.statePreventScrollTop = false;
            return;
        }
        else {
            return { top: 0 }
        }
    },
});



function getStateOptions() {
    if(store.state.options === undefined) {
        store.state.options = {};
        try {
            // @ts-ignore
            fetch(`${import.meta.env.VITE_API_URL}/options?` + new URLSearchParams({
                iso_code: sessionStorage.iso_code ?? '',
            }))
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(response.iso_code){
                        store.state.iso_code = sessionStorage.iso_code = response.iso_code;
                    }
                    /*
                    if(response.ip_location){
                        store.state.ip_location = sessionStorage.ip_location = JSON.stringify(response.ip_location);
                    }
                    else if(sessionStorage.ip_location){
                        response.ip_location = JSON.parse(sessionStorage.ip_location);
                    }
                    */
                    store.commit('setOptions', response);
                });
        } catch (e) {
            console.log(e);
        }
    }
}


async function getStatePage(url: string) {
    let page = null;
    if(store.state.pages[url] !== undefined){
        page = store.state.pages[url];
    }
    else{
        const params = { url };
        if(store.state.iso_code){
            // @ts-ignore
            params.iso_code = store.state.iso_code;
        }
        const response = await fetch(`${import.meta.env.VITE_API_URL}/pages?` + new URLSearchParams(params))
            .then((response) => {
                return response.json();
            });
        page = response.page !== undefined ? response.page : {};
        store.state.pages[url] = page;
    }
    store.commit('setPage', page)
    return page;
}

function setStatePage(url: string) {
    getStatePage(url).then(function (page){
        /*
        if(page.redirect){
            console.log( page.redirect );
            window.location.href = page.redirect;
            return;
        }
        */
        document.title = page?.meta_title ?? 'Brit — сухие и влажные корма для кошек и собак | Брит-Рус';
        // @ts-ignore
        document.querySelector('meta[name="description"]').setAttribute("content", page?.meta_description ?? '');
        // @ts-ignore
        document.querySelector('meta[name="keywords"]').setAttribute("content", page?.meta_keywords ?? '');
    });
}

const iso_code = computed(() => store.state.iso_code);
watch(iso_code, (to, from) => {
    const url = window.location.pathname;
    store.state.pages[url] = undefined;
    getStatePage(url).then(r => {});
});

function resetFooterDogPawsPosition() {
    const appFooter = document.querySelector('footer.app-footer');
    if (!appFooter) return;
    const paws = [...appFooter.querySelectorAll('svg[data-speed]')];
    paws.forEach((paw) => paw.removeAttribute('style'));
}

export default router;
