<script setup lang="js">
import { onMounted } from "vue";

import head_1 from '@/assets/images/brit-mini/head-1.svg';
import head_2 from '@/assets/images/brit-mini/head-2.svg';
import body from '@/assets/images/brit-mini/body.svg';
import walk_right from '@/assets/images/brit-mini/walk-right.svg';
import walk_left from '@/assets/images/brit-mini/walk-left.svg';

import spitz_1 from '@/assets/images/brit-mini/spitz/1.webp';
import spitz_2 from '@/assets/images/brit-mini/spitz/2.webp';


let cycleDogsTimeout = 0;
// let touchStart = false;
let dragStart = false;
let dragOffset = 0;

function getRandomInterval(min, max) {
    return Math.random() * (max - min) + min;
}

function getRandomPosition() {
    //const isRightSide = Math.random() > 0.5;
    const isRightSide = true;
    const position = isRightSide ? 'right' : 'left';
    // const height = getRandomInterval(0, window.innerHeight - 400);
    const height = getRandomInterval(window.innerHeight / 3, window.innerHeight - (window.innerHeight / 3));
    return { position, height, isRightSide };
}

function toggleDogVisibility(visibility) {
    const dog = document.getElementById('dog-mini');
    const { position, height, isRightSide } = getRandomPosition();

    dog.style.top = `${height}px`;

    if (visibility) {
        dog.classList.add('--show');
        if (isRightSide) {
            dog.classList.add('--right');
            dog.classList.remove('--left');
        } else {
            dog.classList.add('--left');
            dog.classList.remove('--right');
        }
    } else {
        dog.classList.remove('--show');
    }
}

function toggleDogOpacity() {
    const dog = document.getElementById('dog-mini');
    const layer1 = dog.querySelector('.animation-layer-1');
    const layer2 = dog.querySelector('.animation-layer-2');

    const isHead1Visible = window.getComputedStyle(layer1).opacity === '1';

    if (isHead1Visible) {
        layer1.style.opacity = '0';
        layer2.style.opacity = '1';
    } else {
        layer1.style.opacity = '1';
        layer2.style.opacity = '0';
    }

    const randomInterval = getRandomInterval(800, 3000);
    setTimeout(toggleDogOpacity, randomInterval);
}

function cycleDogs() {
    if(sessionStorage.BritMiniShowed > 50 ||
        sessionStorage.BritMiniMoved > 50 ||
        sessionStorage.BritMiniClosed > 1 ||
        sessionStorage.BritMiniOpen > 0){
            return;
    }
    sessionStorage.BritMiniShowed = (sessionStorage.BritMiniShowed ?? 0) * 1 + 1;
    toggleDogVisibility(true);
    clearTimeout(cycleDogsTimeout);
    cycleDogsTimeout = setTimeout(() => {
        toggleDogVisibility(false);
        clearTimeout(cycleDogsTimeout);
        cycleDogsTimeout = setTimeout(cycleDogs, 2000); // 3-second delay between dogs
    }, 10000);
}

function handleMouseEnter() {
    clearTimeout(cycleDogsTimeout);
    const dog = document.getElementById('dog-mini');
    dog.classList.add('--move');
    sessionStorage.BritMiniMoved = (sessionStorage.BritMiniMoved ?? 0) * 1 + 1;
    reachGoal('MiniBriShow');
}

function handleMouseLeave() {
    const dog = document.getElementById('dog-mini');
    dog.classList.remove('--move', '--click');
    cycleDogsTimeout = setTimeout(() => {
        cycleDogs();
    }, 1500);
}

function handleClick() {
    if(dragOffset > 5){
        return;
    }
    const dog = document.getElementById('dog-mini');
    if(window.innerWidth < 768){
        if (!dog.classList.contains('--click')) {
            dog.classList.add('--click');
            reachGoal('MiniBriShow');
            return;
        }
    }
    sessionStorage.BritMiniOpen = (sessionStorage.BritMiniOpen ?? 0) * 1 + 1;
    window.open('https://mini.brit-rus.ru/', '_blank');
    reachGoal('MiniBriClick');
}

function handleDrug(dog, value) {
    dragStart = value;
}

function handleMove(dog, value) {
    if(typeof dragStart === 'number'){
        dragOffset = value - dragStart;
        if(dragOffset > 5){
            dog.style.marginRight = -dragOffset + 'px';
        }
    }
}

function handleEnd(dog) {
    dragStart = false;
    if(dragOffset > 10){
        sessionStorage.BritMiniClosed = (sessionStorage.BritMiniClosed ?? 0) * 1 + 1;
        dog.classList.add('--close');
    }
    else{
        dog.style.marginRight = '0px';
    }
}

function reachGoal(goal) {
    if(ym !== undefined){
        ym(61073218, 'reachGoal', goal);
    }
}

onMounted(() => {
    const dog = document.getElementById('dog-mini');
    dog.addEventListener('mouseenter', handleMouseEnter);
    dog.addEventListener('mouseleave', handleMouseLeave);
    dog.addEventListener('click', handleClick);

    dog.addEventListener('mousedown', (event) => { handleDrug(dog, event.clientX); });
    dog.addEventListener('mousemove', (event) => { handleMove(dog, event.clientX); });
    dog.addEventListener('mouseup', (event) => { handleEnd(dog); event.preventDefault(); console.log( 'mouseup' ); });

    dog.addEventListener('touchstart', (event) => { handleDrug(dog, event.changedTouches[0].clientX); });
    dog.addEventListener('touchmove', (event) => { handleMove(dog, event.changedTouches[0].clientX); });
    dog.addEventListener('touchend', (event) => { handleEnd(dog); });

    window.addEventListener('scroll', function(e, el = window) {
        let y = (el.pageYOffset || el.scrollTop) || 0;
        let margin = y;
        let footer = document.querySelector('footer');
        if(footer && footer?.offsetTop){
            margin = Math.min(y, footer.offsetTop - 1000);
        }
        let dogMini = document.getElementById('dog-mini');
        if(dogMini){
            dogMini.style.marginTop = margin + 'px';
        }
    });
    toggleDogOpacity();
    setTimeout(() => {
        cycleDogs();
    }, 5000);
});

</script>

<template>
    <div class="animation-dog-wrapper" id="dog-mini">
        <div class="animation-dog">
            <div class="animation-dog-animal">
                <!--
                <img class="animation-dog-head-1 animation-layer-1" :src="head_1" alt="" draggable="false">
                <img class="animation-dog-head-2 animation-layer-2" :src="head_2" alt="" draggable="false">
                <img class="animation-dog-body" :src="body" alt="" draggable="false">
                -->
                <img class="animation-dog-body-1 animation-layer-1" :src="spitz_1" alt="" draggable="false">
                <img class="animation-dog-body-2 animation-layer-2" :src="spitz_2" alt="" draggable="false">
            </div>
            <img class="animation-dog-walk-right" :src="walk_right" alt="" draggable="false">
            <img class="animation-dog-walk-left" :src="walk_left" alt="" draggable="false">
        </div>
    </div><div class="animation-dog-backdoor"></div>
</template>

<style scoped lang="scss">

.animation-dog-wrapper {
    --offset: 1em;
}

.animation-dog-wrapper {
    font-size: min(15px, calc(calc(1vh + 1vw) * 0.8));
    /* position: fixed */;
    position: absolute;
    width: 12em;
    aspect-ratio: 1;
    z-index: 1000;
    transition: right 0.3s ease,
                left 0.3s ease,
                top 0.3s ease,
                margin-top 0.6s 1.2s ease;
    cursor: pointer;
    display: none;
}

.animation-dog-backdoor{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 50%);
    backdrop-filter: blur(3px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    z-index: 999;
}

.animation-dog {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.animation-dog-animal{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.animation-dog svg, .animation-dog img {
    position: absolute;
    max-width: none;
}

.animation-dog-head-1 {
    top: 1rem;
    z-index: 1001;
    width: 132%;
    left: -0.5em;
    aspect-ratio: 440 / 366;
    opacity: 0;
}

.animation-dog-head-2 {
    top: 2.5em;
    z-index: 1001;
    width: 140%;
    left: -3em;
    aspect-ratio: 454 / 301;
    opacity: 1;
}

.animation-dog-body {
    width: 100%;
    top: 8em;
    aspect-ratio: 305 / 394;
}

.animation-dog-body-1, .animation-dog-body-2 {
    width: 200%;
    top: 2em;
    left: -4em;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.animation-dog-walk-left, .animation-dog-walk-right {
    width: 25em;
    aspect-ratio: 708 / 442;
    opacity: 0;
    visibility: hidden;
}

.animation-dog-wrapper.--show {
    display: block;
}

.animation-dog-wrapper.--right.--show {
    right: var(--offset);
}

.animation-dog-wrapper.--right.--move {
    right: calc(var(--offset) + 1.5em);
}

.animation-dog-wrapper.--right.--show.--move .animation-dog-walk-right {
    z-index: 1001;
    top: -4em;
    left: -24em;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.animation-dog-wrapper.--right .animation-dog-animal{
    transform: rotateZ(-52deg);
}

.animation-dog-wrapper.--close {
    opacity: 0;
    visibility: hidden;
}

.animation-dog-wrapper.--show.--move + .animation-dog-backdoor {
    opacity: 1;
    visibility: visible;
}



/*
.animation-dog-wrapper-right {
    transform: rotateZ(-52deg);
    right: -10em;
    display: block;
}
*/

/*
.animation-dog-wrapper-left {
    transform: rotateZ(52deg);
    left: -10em;
    display: block;
}
*/

/*
.animation-dog-wrapper-left.show {
    left: var(--offset);
}
*/

/*
.animation-dog-wrapper.move-left {
    left: calc(var(--offset) + 1.5em);
}
*/

/*
.animation-dog-wrapper.move-left .animation-dog-walk-left {
    opacity: 1;
}
*/

</style>